import { z } from 'zod';

export const jeopardyBoardSchema = z
  .object({
    board: z
      .object({
        categories: z
          .array(
            z
              .object({
                id: z.string().describe('Category ID'),
                name: z.string().describe('Category Name'),
                clues: z
                  .array(
                    z
                      .object({
                        id: z.string().describe('Clue ID'),
                        question: z.string().describe('Clue Question'),
                        answer: z.string().describe('Clue Answer'),
                        value: z.number().describe('Clue Point Value'),
                      })
                      .strict()
                  )
                  .describe('The knowledge clues belong to the category'),
                nameScript: z.string(),
              })
              .strict()
          )
          .describe('A list of Jeopardy categories'),
      })
      .strict()
      .describe('The Jeopardy Board'),
  })
  .strict();
